import React, {useEffect} from 'react';

function MobileMenuItem({item, openMenu, closeMenu, selected}) {

    useEffect(() => {
        console.log('selected')
    }, [selected])
    function toggleMenu(e) {
        if (selected === item.id) {
            closeMenu(e)
        } else {
            openMenu(e, item)
        }
    }

    return <div id="" className="m-dropdown">
        <a href={item.url}
           onClick={toggleMenu}
            className={`m-second-menu__item second-menu__item second-menu__link ${selected === item.id ? '_active': ''} ${item.has_children ? '_toggle' : ''}`}>{item.title}</a>
    </div>
}

export default MobileMenuItem