import React from 'react';

function MenuItem({item})    {
    return (
        <a href={item.url} className="submenu__item" key={item.id} >
            <span>{item.title}</span>
            {item.image && <img src={item.image} alt=""/>}
        </a>
    )
}

export default MenuItem