import React from "react";

function Banner(params) {
    return <a href={params.link} className="banner">
        <div className="banner__header">
            <h3 className="banner__title">{ params.title}</h3>
            <p className="banner__sub-title">{params.sub_title}</p>
        </div>
        <img src={params.image} alt={params.title} className="banner__img"/>
    </a>
}

export default Banner