import React, {useState, useEffect, useRef} from "react";

import Logo from "../assets/Logo.svg";
import MobileMenuItem from "./MobileMenuItem";
import BurgerMenu from "./BurgerMenu";

function MobileMenu ({menu, obr_button, sell_button, loading, socials, phone, loginUrl, menuError}) {
    const ref = useRef()
    const [submenu, setSubmenu] = useState([])
    const [headerHeight, setHeaderHeight] = useState(0)
    const [selected, setSelected] = useState(null)

    useEffect(() => {
        document.addEventListener('click', windowClick);
    }, [])

    const windowClick = (e) => {
        const target = e.target
        if (ref.current && !ref.current.contains(target)) {
            console.log("click outside")
            closeMenu()
        } else {
            console.log("click inside")
            if (target.classList.contains('_closeMenu')) {
                closeMenu()
            }
        }
    }
    function openMenu (e, item) {
        e.preventDefault()
        const sub_items = menu.filter(i => i.parent === item.id)
        if (sub_items.length > 0) {
            setSubmenu(sub_items)
            setSelected(item.id)
            const header = document.querySelector('.header-mobile')
            setHeaderHeight(header.offsetHeight + header.offsetTop)
        } else {
            window.location.href = item.url
        }
    }
    function closeMenu (e = null) {
        if (e)
            e.preventDefault()
        setSubmenu([])
        setSelected(null)
    }

    return (
        <section className="header-mobile" ref={ref}>
            <div className="header-mobile__wrap">
                <a href="/" className="d-flex align-items-center">
                    <img src={Logo} className="header-logo"/>
                </a>
                <span className="beta">Beta-версия</span>
                <BurgerMenu menu={menu} socials={socials} loading={loading} menuError={menuError} loginUrl={loginUrl} phone={phone}/>
            </div>
            {loading ?
                menuError ? <>{menuError}</> :
                    <div id="second-menu_mobile" className="m-second-menu loading">
                        <div className="m-second-menu__wrap">
                            <div id="" className="m-dropdown" style={{height: '44px'}}>
                            </div>
                        </div>
                    </div>
                :
                <div id="second-menu_mobile" className="m-second-menu">
                    <div className="m-second-menu__wrap">
                        {menu.filter(item => item.level === 0)?.map(item => (
                            <MobileMenuItem key={item.id} selected={selected} openMenu={openMenu} closeMenu={closeMenu} item={item} />
                        ))}
                        {sell_button && <MobileMenuItem key={sell_button.id} selected={selected} openMenu={openMenu} closeMenu={closeMenu} item={sell_button} />}
                        {obr_button && <MobileMenuItem key={obr_button.id} selected={selected} openMenu={openMenu} closeMenu={closeMenu} item={obr_button} />}
                    </div>
                    {submenu.length > 0 && <div className="m-dropdown__wrap">
                        {submenu.map((item) => (
                            <a href={item.url} className="m-dropdown__link" key={item.id}>{item.title}</a>
                        ))}
                    </div>
                    }
                </div>
            }

        </section>
    )
}

export default MobileMenu