import React, {useState} from 'react';

import CyanFilter from "../assets/filter/cyan-filter.svg"

function MenuItem({item, openMenu, classes, closeMenu, selected})    {
    const [buttonOver, setButtonOver] = useState(false)

    const toggleMenu = (e) => {
        if (selected === item.id) {
            closeMenu(e)
        } else {
            openMenu(e, item)
        }
    }

    return <a href={item.url}
              className={`nav__item ${classes ? classes : '' } ${selected === item.id ? '_active': ''}`}
              onMouseLeave={() => setButtonOver(false)}
              onMouseEnter={() => setButtonOver(true)}
              onClick={toggleMenu}>
        {item.image && <img src={item.image} style={buttonOver ? {filter: `url(${CyanFilter}#filter)`} : {filter: 'none'}}/>}
        {item.title}
        {item.alert && <span className="item__alert">{item.alert}</span>}
    </a>
}

export default MenuItem