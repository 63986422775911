import React, {useEffect, useState} from 'react';

import axiosClient from "./api/axiosClient";
import DesktopMenu from "./components/DesktopMenu";
import MobileMenu from "./components/MobileMenu";

function App() {
    const [menu, setMenu] = useState([])
    const [menuError, setMenuError] = useState(false)
    const [obr_button, setObrButton] = useState(null)
    const [sell_button, setSellButton] = useState(null)
    const [loading, setLoading] = useState(true)
    const [mobile, setMobile] = useState(false)

    const [loginUrl, setLoginUrl] = useState('https://cabinet.bik31.ru/') /* '/login/' */
    const [phone, setPhone] = useState('8 800 301 29 31')
    const [socials, setSocials] = useState([
        {
            url: 'https://t.me/ao_bik31',
            name: 'telegram',
        },{
            url: 'https://ok.ru/group/70000000091730',
            name: 'odnoklassniki',
        },{
            url: 'https://vk.com/bik_31',
            name: 'vk',
        },
    ])

    useEffect(() => {
        const ax = axiosClient.get('navigation/?status=p', {timeout: 5000});
        ax.then((response) => {
            //console.log("ax response", response)
            const obrashenie = response.findIndex(item => item.slug === 'obrashenie')
            if (obrashenie !== -1) {
                setObrButton(response[obrashenie])
                response.splice(obrashenie, 1);
            }
            const sell = response.findIndex(item => item.slug === 'razmestit-svoj-uchastok')
            if (sell !== -1) {
                setSellButton(response[sell])
                response.splice(sell, 1);
            }
            const new_alert = response.findIndex(item => item.slug === 'buy-land')

            if (new_alert !== -1)
                response[new_alert].alert = 'Новые'

            setMenu(response)
            setLoading(false)
        })
            .catch((error) => {
                setMenuError(error.message)
               console.log("ax error", error)
            })

        setMobile(sMobile())

    }, [menuError])

    const sMobile = () => {
        const Android = () => {return navigator.userAgent.match(/Android/i)}
        const BlackBerry = () => {return navigator.userAgent.match(/BlackBerry/i)}
        const iOS = () => {return navigator.userAgent.match(/iPhone|iPad|iPod/i)}
        const Opera = () => {return navigator.userAgent.match(/Opera Mini/i)}
        const Windows = () => {return navigator.userAgent.match(/IEMobile/i)}
        return (Android() || BlackBerry() || iOS() || Opera() || Windows())
    }

    return (
        <>
            { mobile
                ? <MobileMenu menu={menu} obr_button={obr_button} sell_button={sell_button} loading={loading} socials={socials} phone={phone} loginUrl={loginUrl} />
                : <DesktopMenu menu={menu} obr_button={obr_button} sell_button={sell_button} loading={loading} socials={socials} phone={phone} loginUrl={loginUrl} />
            }
        </>
    )
}

export default App;