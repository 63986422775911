import React, {useState, useEffect} from 'react';

function MenuCollapse({item, children, menu, is_top_level = false}) {
    const [open, setOpen] = useState(false)
    const [active, setActive] = useState(false)

    useState(() => {
        const path_array = window.location.pathname.split('/')
        console.log('path_array', path_array)
        if (path_array.includes(item.slug)) {
            setActive(true)
            setOpen(true)
        }
    }, [])

    const handler = (e) => {
        e.preventDefault();
        if (children?.length > 0) {
            console.dir(e.currentTarget)
            setOpen(!open)
        } else {
            console.dir(e.currentTarget)

            window.location = e.currentTarget.href
        }
    }

    return (
        <div id="" className={`m-collapse ${active ? 'active' : ''} ${ open ? 'open' : ''}`}>
            <a className={`m-collapse__header ${is_top_level ? '_top' : ''} ${children?.length > 0 ? '_toggle' : ''}`} onClick={handler} href={item.url}>
                <p className="m-collapse__title">{item.title}</p>
            </a>
            {open && <div className="m-collapse__wrap">
                {children?.map(el => (
                    <MenuCollapse key={el.id} item={el} children={menu.filter(i => i.parent === el.id)} menu={menu} />
                ))}
            </div> }

        </div>
    )
}

export default MenuCollapse