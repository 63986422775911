import React, {useState} from 'react';
import MobileCollapse from "./MobileCollapse";

function BurgerMenu({menu, socials, loading, menuError, loginUrl, phone}) {
    const [open, setOpen] = useState(false)
    return (
        <>
            <div className={`burger ${open ? 'active' : ''}`} onClick={() => setOpen(!open)}><span></span></div>
            {open &&
            <div className="nav-mobile">
                {loading ?
                    menuError ? <>{menuError}</> :
                        <div className="menu _first">Загрузка</div>
                    :
                    <div className="menu _first mb-3" style={{marginBottom: '24px'}}>
                        {menu.filter(item => item.level === 0)?.map(item => (
                            <MobileCollapse key={item.id} item={item} children={menu.filter(i => i.parent === item.id)} menu={menu} is_top_level={true}/>
                        ))}
                    </div>
                }
                <div className="d-flex align-items-end justify-content-between">
                    <a href={loginUrl} className="login-button">Личный кабинет</a>
                    <div>
                        <div className="nav-mobile__phone-wrap">
                            <a className="nav-mobile__phone" href={`tel:${phone}`}>{phone}</a>
                            <span className="nav-mobile__phone-title">Звонок по России бесплатный</span>
                        </div>
                    </div>
                </div>
                <div className="nav-mobile__socials">
                    {socials.map(social => (
                        <a href={social.url} key={social.name} className={`header__social _${social.name}`}></a>
                    ))}
                </div>
            </div>
            }
        </>
    )
}

export default BurgerMenu