import React, {useEffect, useRef, useState} from 'react';
import '../App.scss'

import axiosClient from "../api/axiosClient";
import MenuItem from "./MenuItem";
import SubMenuItem from "./SubMenuItem";
import Banner from "./Banner";
import Logo from "../assets/Logo.svg"
import X_close from "../assets/icons/x-close.svg"



function DesktopMenu({menu, obr_button, sell_button, loading, socials, phone, loginUrl, menuError}) {
    const ref = useRef()
    const [submenu, setSubmenu] = useState([])
    const [headerHeight, setHeaderHeight] = useState(0)
    const [selected, setSelected] = useState(null)
    const [banner_info, setBannerInfo] = useState(null)
    const [loadingSub, setLoadingSub] = useState(false)

    const [alert, setAlert] = useState(false)

    useEffect(() => {
        if (localStorage.getItem('alert') !== 'close') {
            setAlert(true)
        }
        //localStorage.removeItem('alert')
        document.addEventListener('click', windowClick);
    }, [])

    const windowClick = (e) => {
        const target = e.target
        if (ref.current && !ref.current.contains(target)) {
            console.log("click outside")
            closeMenu()
        } else {
            console.log("click inside")
            if (target.classList.contains('_closeMenu')) {
                closeMenu()
            }
        }
    }
    function openMenu (e, item) {
        e.preventDefault()
        const sub_items = menu.filter(i => i.parent === item.id)

        if (sub_items.length > 0) {
            setLoadingSub(true)
            setSubmenu([])
            function setMenu() {
                setSubmenu(sub_items)
                setSelected(item.id)
                const header = document.querySelector('.header')
                setHeaderHeight(header.offsetHeight + header.offsetTop)
            }

            const ax = axiosClient.get(`custom-card/?slug=${item.slug}`);

            ax.then((response) => {
                if (response.length > 0) {
                    setBannerInfo(response[0])
                    setMenu()
                } else {
                    setBannerInfo(null)
                    setMenu()
                }
                setLoadingSub(false)
            })
                .catch(error => {
                    console.error(error)
                })

        } else {
            window.location.href = item.url
        }
    }
    function closeMenu (e = null) {
        if (e)
            e.preventDefault()
        setSubmenu([])
        setSelected(null)
    }

    function closeAlert(e) {
        e.preventDefault();
        setAlert(false)
        localStorage.setItem('alert', 'close');
    }

    return (
        <>
            {alert && <div className="container-center">
                <div className="alert alert-info">
                    <div className="alert__text-wrap">
                        <span className="bold">Внимание!</span><p>Сайт находится в тестовом режиме.</p>
                    </div>
                    <a href="" className="alert__close" onClick={closeAlert}>
                        <img src={X_close} alt="Закрыть"/>
                    </a>
                </div>
            </div>}

            <section className="header">
                <div className="container-center">
                    <div className="header__top">
                        <a href="/" className="header__logo-link">
                            <img src={Logo} className="header__logo-img"/>
                            <span className="header__logo-text">Белгородская <br/>ипотечная корпорация</span>
                        </a>
                        <div className="header__info">
                            <a className="header__phone" href={`tel:${phone}`}>{phone}</a>
                            <div className="header__social-list">
                                {socials.map(social => (
                                    <a href={social.url} key={social.name} className={`header__social _${social.name}`}></a>
                                ))}
                            </div>
                            <a className="header__login" href={loginUrl}>Личный кабинет</a> {/*login*/}
                        </div>
                    </div>

                    <div className="desktop-nav _closeMenu" ref={ref}>
                        {loading ?
                            menuError ? <>{menuError}</> :
                                <>
                                    <div className="nav__items">
                                        <div className="fake-item"></div>
                                        <div className="fake-item"></div>
                                        <div className="fake-item"></div>
                                        <div className="fake-item"></div>
                                    </div>
                                    <div className="nav__extra-items">
                                        <div className="fake-item _extra"></div>
                                        <div className="fake-item _extra"></div>
                                    </div>
                                </>
                            :
                            <>
                                <div className="nav__items">
                                    {menu.filter(item => item.level === 0)?.map(item => (
                                        <MenuItem key={item.id} item={item} openMenu={openMenu} closeMenu={closeMenu} selected={selected} />
                                    ))}
                                </div>
                                <div className="nav__extra-items">
                                    {sell_button && <MenuItem key={sell_button.id} classes={'_extra'} item={sell_button} openMenu={openMenu} closeMenu={closeMenu} selected={selected} />}
                                    {obr_button && <MenuItem key={obr_button.id} classes={'_extra'} item={obr_button} openMenu={openMenu} closeMenu={closeMenu} selected={selected} />}
                                </div>
                            </>
                        }
                        {loadingSub && <div className="submenu__backdrop _closeMenu" style={{height: `calc(100vh - ${headerHeight}px)`}}>
                            <div className="container-center scroll-y _closeMenu">
                                <div className="submenu-loader">
                                    <div className="submenu__banner-container _closeMenu">
                                        <div className="banner-container _closeMenu">
                                            <div className="fake-bunner"></div>
                                        </div>
                                        <div className="submenu__container _closeMenu">
                                            <div className="fake-subitem"></div>
                                            <div className="fake-subitem"></div>
                                            <div className="fake-subitem"></div>
                                            <div className="fake-subitem"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }

                        {submenu.length > 0 && <div className="submenu__backdrop _closeMenu" style={{height: `calc(100vh - ${headerHeight}px)`}}>
                            <div className="container-center scroll-y _closeMenu">
                                <div className="submenu__banner-container _closeMenu">
                                    {banner_info &&
                                    <div className="banner-container _closeMenu">
                                        <Banner {...banner_info} />
                                    </div>}
                                    <div className="submenu__container _closeMenu">
                                        {submenu.map((item) => (
                                            <SubMenuItem item={item} key={item.id}/>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>

                </div>
            </section>
        </>
    )
};

export default DesktopMenu;